import React, { forwardRef, memo } from 'react';
import {
  Box,
  Center,
  Flex,
  Img,
  Stack,
  VStack,
  Text,
  Grid,
  Accordion,
  Link,
} from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { offices, socialMedias } from 'constants/footer';
import Wrapper from 'shared/Wrapper';
import OfficeInfo from 'shared/OfficeInfo';
import AccordionInfo from 'shared/AccordionInfo';
import downAngleWhite from 'assets/Icons/DownAngleWhite.svg';
import footerLogo from 'assets/footerLogo.png';

const Footer = (props, ref) => {
  return (
    <VStack
      ref={ref}
      w="full"
      id="footer"
      pos="relative"
      zIndex={3}
      bgColor="bg.black"
      boxSizing="border-box"
      as="footer"
      borderRadius={{
        laptop: '56px 56px 0px 0px',
        tablet: '48px 48px 0px 0px',
        mobile: '32px 32px 0px 0px',
      }}
      color="bg.grayLight"
      pt={{ mobile: '32px', tablet: '52px', laptop: '100px' }}
      {...props}
    >
      <Wrapper gap="40px" mb={{ laptop: '100px', mobile: '40px' }} maxW="1320px">
        <Stack
          justify="space-between"
          spacing={{ mobile: '32px', tablet: '15px' }}
          direction={{ tablet: 'row', mobile: 'column' }}
          w="full"
        >
          <Text color="text.white" as="h2">
            Our offices
          </Text>
          <VStack justify="end" align="start" spacing={{ laptop: '24px', mobile: '20px' }}>
            <Flex gap="16px" whiteSpace="nowrap">
              <Img
                src={commonAssets.icons.Phone.black}
                alt="Phone"
                color="text.white"
                alignItems="center"
                filter="brightness(0) invert(1)"
              />
              <Center textStyle="h1">+7 (495) 138 39 39</Center>
            </Flex>
            <Link href="mailto:info@astondevs.com">
              <Flex gap="16px" whiteSpace="nowrap" fill="text.white">
                <Img
                  src={commonAssets.icons.Mail.black}
                  alt="Mail"
                  color="text.white"
                  alignItems="center"
                  filter="brightness(0) invert(1)"
                />
                <Center textStyle="h1">info@astondevs.com</Center>
              </Flex>
            </Link>
            <Flex
              gap={{ mobile: '18px', laptop: '32px' }}
              flexWrap="nowrap"
              w="full"
              pos="relative"
            >
              {socialMedias.map(({ src, href, alt }, i) => (
                <Link key={i} href={href} target="_blank">
                  <Img src={src} alt={alt} boxSize={{ mobile: '24px', laptop: '40px' }} />
                </Link>
              ))}
            </Flex>
          </VStack>
        </Stack>
        <Grid
          hideBelow="sm"
          justifyContent="space-between"
          columnGap="101px"
          w="full"
          templateColumns={{
            laptop: 'repeat(3, minmax(auto, 346px))',
            sm: 'repeat(2, minmax(auto, 340px))',
            mobile: 'repeat(1, auto)',
          }}
          rowGap={{ laptop: '49px', mobile: '32px' }}
        >
          {offices?.map(val => (
            <OfficeInfo key={val.city} {...val} />
          ))}
        </Grid>
        <Accordion
          allowToggle
          flex={1}
          w="full"
          gap="20px"
          display="flex"
          flexDirection="column"
          hideFrom="sm"
        >
          {offices?.map(({ city, address }, i) => (
            <AccordionInfo
              key={i}
              title={city}
              content={address}
              panelStyle={{ textStyle: 'h2', color: '#A7A9A5', ps: '20px', pt: '12px' }}
              leftIcon={
                <Box borderRadius="full" placeSelf="center" boxSize="8px" bgColor="bg.accent" />
              }
              rightIcon={downAngleWhite}
            />
          ))}
        </Accordion>
      </Wrapper>
      <Box w="full" px={{ laptop: '20px' }}>
        <Img src={footerLogo} alt="Logo" w="full" />
      </Box>
    </VStack>
  );
};

export default memo(forwardRef(Footer));
